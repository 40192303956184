// import i18n from '@/i18n/index'

export default {
  basicInformation: [
    { prop: 'address' },
    { prop: 'charge_points' },
    { prop: 'lat' },
    { prop: 'lon' },
    { prop: 'name' }
  ]
}
