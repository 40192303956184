<template>
  <div class="managerUserOrderView">
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="General" name="general">
          <custome-table tableType="object" :custSpan="6" :data="custName" :dataItem="basicInformation" @forwardToPage="forwardToPage"></custome-table>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import loading from '@/utils/loading.json'
import apis from '@/services'
import customeTable from '@/components/customeTable'
import jsonData from './jsonData'
import { mixin } from '@/mixins/viewDiolog'

export default {
  components: { customeTable },
  mixins: [mixin],
  data () {
    return {
      custName: {},
      activeName: 'general',
      basicInformation: jsonData.basicInformation
    }
  },
  created () {
    this.init()
  },
  methods: {
    // 初始化
    async init () {
      this.$store.state.app.loading = this.$loading(loading)
      const res = await apis.AssetApi.getLocationDtails(this.$route.params.id)
      if (res.status === 200) {
        this.custName = res.data.location
      }
      this.$store.state.app.loading.close()
    },
    forwardToPage ({ model_name, id }, name) {
      if (model_name && model_name === 'charge_point') {
        this.$router.push({ name: 'chargingPointDetail', params: { id } })
      }
    },
    async handleClick (type, data) { }
  }
}
</script>
<style lang="scss" scope></style>
