/**
 * 公共常量
 */
const constant = {
  // 表单配置
  FORM_OPTION: {
    // input
    INPUT: {
      // 最大字数
      MAX_LENGTH: {
        COMMON: 9999999 // 通用
      }
    },
    // textarea
    TEXT_AREA: {
      // 最大字数
      MAX_LENGTH: {
        COMMON: 9999999 // 通用
      }
    }
  },
  // 页面跳转间隔时间
  ROUTER_CHANGE_INTERVAL: 800
}

export default constant
