import Utils from '@/utils/Utils'
import loading from '@/utils/loading.json'
import constant from '@/utils/constant.js'

// import { mapState } from 'vuex'
import { getDeliverDate } from '@/services/common.js'

export const mixin = {
  data () {
    return {
      // tableHeight: undefined
      dialogData: [],
      dialogVisible: false,
      batchData: undefined,
      ids: [],
      dateArray: [],
      forceable: false,
      isActive: false
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  mounted () {
  },
  methods: {
    async handleCusClick (data, row) {
      this.batchData = data
      if (data.extra_params && data.extra_params.length > 0) {
        this.dialogData = data.extra_params
        const index = data.extra_params.findIndex(item => item.key === 'delivery_date')
        if (index !== -1 && row) {
          const value = await getDeliverDate(row)
          if (value.data.shop.host_week_delivery_time_slots) {
            this.dateArray = value.data.shop.host_week_delivery_time_slots
          }
        }
        this.forceable = !!data.forceable
        this.dialogVisible = true
      } else {
        Utils.custOperate(data, this)
      }
    },
    // 删除数据
    deleteClick (data, delApi, name, params) {
      const ex_parmas = {}
      if (params) {
        Object.assign(ex_parmas, params)
      }
      // mock操作
      this.$confirm(this.$t('text.confirmDeleteThisItem'), this.$t('text.tips'), {
        confirmButtonText: this.$t('text.confirmation'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(() => {
        this.$store.state.app.loading = this.$loading(loading)
        delApi(data).then(res => {
          this.$store.state.app.loading.close()
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name,
              params: ex_parmas
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
          // this.search()
        }).catch(() => this.$store.state.app.loading.close())
      }).catch(() => {
      })
    },
    doNextConfirm (data) {
      Utils.custOperate(this.batchData, this, {}, data)
    }
  }
}
